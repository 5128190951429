import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'
import ky from 'ky'
import type { ResponseBody422 } from '@/types/api'

type CatchError<T = undefined> = {
  catcher: ({ message }: ky.HTTPError) => Promise<T>
}

const catchError = <T = undefined>(returnValue: T): CatchError<T> => {
  const catcher = async ({ response, message }: ky.HTTPError): Promise<T> => {
    const { dispatch } = useStore()

    if (response.status === 422) {
      const { description } = (await response.json()) as ResponseBody422
      dispatch(ActionTypes.SetNotice, {
        message: description,
      })
    } else {
      dispatch(ActionTypes.SetNotice, {
        message,
      })
    }
    return returnValue
  }

  return { catcher }
}

export { catchError }
