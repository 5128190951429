<template>
  <span
    class="rounded inline-block bg-white shadow p-1 sm:p-2 transition text-gray-300 focus-within:text-blue-400 focus-within:ring-2 hover:bg-gray-50 duration-200"
    :title="t('title')"
  >
    <mdi-magnify />
    <input
      ref="input"
      :value="modelValue"
      class="bg-transparent sm:focus:w-44 transition-all delay-100 w-32 lg:w-24 duration-500 ml-2 text-gray-600 outline-none focus:outline-none"
      spellcheck="false"
      :placeholder="t('title')"
      :maxlength="100"
      @input="onInput"
      @keydown.esc="onEscape"
    />

    <button
      :class="{ invisible: !modelValue.length }"
      class="text-gray-400 hover:text-blue-400 transition duration-200"
      :title="t('erase')"
      @click.stop="$emit('update:modelValue', '')"
    >
      <mdi-close />
    </button>
    <button
      :title="t('shortcut')"
      class="shadow ml-1 hidden lg:inline hover:shadow-md transition text-gray-300 hover:text-blue-400 duration-200 outline-none rounded"
      @click="onFocus"
    >
      <mdi-slash-forward />
    </button>
  </span>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, onUnmounted, computed } from 'vue'
import MdiMagnify from '@/components/base/icons/mdi/MdiMagnify.vue'
import MdiClose from '@/components/base/icons/mdi/MdiClose.vue'
import MdiSlashForward from '@/components/base/icons/mdi/MdiSlashForward.vue'
import { useI18n } from 'vue-i18n'
import debounce from 'lodash.debounce'

export default defineComponent({
  components: {
    MdiMagnify,
    MdiClose,
    MdiSlashForward,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],

  setup(_, { emit }) {
    const { t } = useI18n()
    const input = ref<HTMLInputElement>()
    const onFocus = (): void => input.value?.focus()

    const focusOnSlash = async ({ key }: KeyboardEvent): Promise<void> => {
      if (key !== '/' || !input.value || document.activeElement === input.value)
        return
      onFocus()
    }

    const onEscape = (): void => {
      input.value?.blur()
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', focusOnSlash)
    })

    onUnmounted(() => {
      window.removeEventListener('keyup', focusOnSlash)
    })

    const emitter = ({ target }: Event) => {
      if (!(target instanceof HTMLInputElement)) return
      emit('update:modelValue', target.value)
    }

    const onInput = debounce(emitter, 200)

    return {
      t,
      input,
      onEscape,
      onFocus,
      onInput,
    }
  },
})
</script>

<i18n lang="yml">
en:
  title: Search
  erase: Erase
  shortcut: Shortcut
ja:
  title: 検索
  erase: 消去
  shortcut: ショートカット
</i18n>

<style>
input:focus + button + .aaa {
  @apply opacity-0;
}
</style>
