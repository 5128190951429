import { http } from '@/plugins/ky'
import type { CustomerStatus } from '@/types'
import { ADMIN } from '@/constants'
import { join } from 'path'

const ADMIN_TRAIALS = join(ADMIN, 'trials')

type Trial = {
  customerId: string
  servieName: string
  customerName: string
  applicationDate: Date
  status: CustomerStatus
  startDate: Date | string
  expireDate: Date | string
}

type GetResponse = Trial[]

const get = () =>
  http
    .get(ADMIN_TRAIALS, {
      parseJson: (text) =>
        JSON.parse(text, (key, value) => {
          if (
            ['applicationDate', 'startDate', 'expireDate'].includes(key) &&
            typeof value === 'string' &&
            !!value
          ) {
            try {
              return new Date(Date.parse(value))
            } catch {
              return value
            }
          }

          return value
        }),
    })
    .json<GetResponse>()
export { get, GetResponse, Trial, ADMIN_TRAIALS }
