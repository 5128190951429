
import { defineComponent, ref } from 'vue'
import { post } from '@/functions/side-effects/api/admin/customer/appication'
import { useI18n } from 'vue-i18n'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import { useProgress } from '@/reactives'
import { catchError } from '@/composites'

export default defineComponent({
  components: {
    MdiAlert,
    ButtonClose,
    BaseButton,
  },
  emits: ['close', 'success'],

  setup(_, { emit }) {
    const { t } = useI18n()
    const _customerId = ref<string>('')
    const _text = ref<string>('')
    const { isProgress, done, safeCall } = useProgress()
    const { catcher } = catchError(undefined)

    const setId = (customerId: string, text: string) => {
      _customerId.value = customerId
      _text.value = text
    }
    const onRefuse = () => {
      safeCall(
        post({
          customerId: _customerId.value,
          isApproval: false,
          service: 'qt-dns',
        })
          .then(() => emit('success'))
          .catch(catcher)
          .then(done)
      )
    }

    return {
      onRefuse,
      setId,
      t,
      isProgress,
      text: _text,
    }
  },
})
