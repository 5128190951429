<template>
  <base-mdi-icon :d="mdiAlert" />
</template>

<script lang="ts">
import { mdiAlert } from '@mdi/js'
import BaseMdiIcon from '@/components/base/icons/mdi/BaseMdiIcon.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BaseMdiIcon,
  },
  setup() {
    return {
      mdiAlert,
    }
  },
})
</script>
