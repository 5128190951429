import type { Trial } from '@/functions/side-effects/api/admin/trials'
import type { CustomerStatus } from '@/types'
import { sort } from 'rambda'
import i18n from '@/plugins/i18n'

const getLocaleDateOrString = (val: string | Date): string =>
  typeof val === 'string' ? val : val.toLocaleDateString()

const sortByStatus = (a: Trial, b: Trial): 1 | 0 | -1 => {
  const sortOrder: CustomerStatus[] = ['unapproved', 'trial', 'beta', 'refused']

  const a1 = sortOrder.indexOf(a.status)
  const b1 = sortOrder.indexOf(b.status)
  if (a1 === b1) {
    return a.applicationDate > b.applicationDate ? 1 : -1
  }
  return a1 > b1 ? 1 : -1
}

type Verdict = 'info' | 'approve' | 'refuse'

const componentResolver = (verdict: Verdict): string => {
  switch (verdict) {
    case 'info': {
      return 'customer-detail'
    }

    case 'approve': {
      return 'customer-approve'
    }

    case 'refuse': {
      return 'customer-refuse'
    }
  }
}

const attrsResolver = (
  verdict: Verdict,
  {
    info,
    approve,
    refuse,
  }: {
    info: { [k: string]: Function }
    approve: { [k: string]: Function }
    refuse: { [k: string]: Function }
  }
) => {
  switch (verdict) {
    case 'info': {
      return info
    }

    case 'approve': {
      return approve
    }

    case 'refuse': {
      return refuse
    }
  }
}

const isUnapproved = (payload: CustomerStatus): boolean =>
  payload === 'unapproved'
const hasUnapproved = (trials: Trial[] | Trial): boolean => {
  if (Array.isArray(trials)) {
    return trials.some((trial) => isUnapproved(trial.status))
  } else {
    return isUnapproved(trials.status)
  }
}

type Item = Trial

const getResponseParser = (response: Trial[]): Item[] =>
  response.map(({ applicationDate, ...rest }) => {
    return {
      ...rest,
      applicationDate,
    }
  })

const sorter = (items: readonly Item[]): readonly Item[] =>
  sort(sortByStatus, items)

const customStatusFilter = (val: CustomerStatus, search: string): boolean => {
  return i18n.global.t(val).includes(search)
}

export {
  componentResolver,
  attrsResolver,
  hasUnapproved,
  getResponseParser,
  getLocaleDateOrString,
  sorter,
  customStatusFilter,
  Verdict,
  Item,
}
