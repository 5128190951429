<template>
  <h3 class="p-4">
    <button-close
      :disabled="isProgress"
      class="rounded-full shadow bg-gray-50 hover:bg-gray-100 p-1 hover:shadow-md transition duration-200 focus:outline-none focus:ring-2"
      @click="$emit('close')"
    />

    <span class="ml-4 align-middle capitalize">{{ t('title') }}</span>
  </h3>

  <div class="flex flex-col gap-4 items-center p-4 flex-nowrap justify-center">
    <mdi-alert class="text-yellow-500 w-20 h-20" />

    <span
      class="text-red-400 py-0.5 px-2 text-sm text-center rounded shadow hover:shadow-md transition-shadow duration-200 bg-gray-100"
    >
      {{ text }}
    </span>
    <p>
      {{ t('description') }}
    </p>

    <base-button
      class="w-full bg-green-100 p-1 capitalize disabled:bg-gray-100 focus:ring-2 shadow rounded hover:shadow-md transition duration-200 text-green-800"
      :disabled="isProgress"
      :loading="isProgress"
      @click="onApprove"
    >
      {{ t('approve') }}
    </base-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { post } from '@/functions/side-effects/api/admin/customer/appication'
import { useI18n } from 'vue-i18n'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import { useProgress } from '@/reactives'
import { catchError } from '@/composites'

export default defineComponent({
  components: {
    MdiAlert,
    ButtonClose,
    BaseButton,
  },
  emits: ['close', 'success'],

  setup(_, { emit }) {
    const { t } = useI18n()
    const _customerId = ref<string>('')
    const _text = ref<string>('')
    const { isProgress, done, safeCall } = useProgress()
    const { catcher } = catchError(undefined)

    const setId = (customerId: string, text: string) => {
      _customerId.value = customerId
      _text.value = text
    }
    const onApprove = () => {
      safeCall(
        post({
          customerId: _customerId.value,
          isApproval: true,
          service: 'qt-dns',
        })
          .then(() => emit('success'))
          .catch(catcher)
          .then(done)
      )
    }

    return {
      onApprove,
      setId,
      t,
      isProgress,
      text: _text,
    }
  },
})
</script>

<i18n lang="yml">
en:
  title: customer approval
  description: Do you approve?
ja:
  title: カスタマーの承認
  description: 承認しますか？
</i18n>
