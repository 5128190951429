import { http } from '@/plugins/ky'
import { ADMIN } from '@/constants'
import { join } from 'path'

const ADMIN_CUSTOMER = join(ADMIN, 'customer')
type GetResponse = {
  name: string
  [k: string]: string
}

const get = (customerId: string) =>
  http
    .get(ADMIN_CUSTOMER, {
      searchParams: {
        customerId,
      },
    })
    .json<GetResponse>()
export { get, GetResponse, ADMIN_CUSTOMER }
