<template>
  <div class="flex justify-between items-end mb-2">
    <span class="text-gray-600">
      <drumroll class="px-1" :count="items.length" />
      {{ t('count') }}
    </span>
    <input-search v-model.trim="search" />
  </div>
  <div
    class="overflow-x-scroll mt-3 rounded hover:shadow-md transition-shadow duration-200 shadow"
  >
    <base-table v-bind="baseTableAttrs">
      <template #header:actions="{ items }">
        <th v-show="hasUnapproved(items)" />
      </template>
      <template #item="{ item, className, items }">
        <td :class="className">{{ item.serviceName }}</td>
        <td :class="className">
          <button
            class="group relative"
            :title="item.customerName"
            @click="onClick(item)"
          >
            <mdi-magnify
              :title="t('detail')"
              class="opacity-0 group-hover:opacity-100 delay-300 transform ease-in-out -translate-x-4 group-hover:text-blue-500 group-hover:translate-x-0 transition duration-200 absolute -right-8"
            />

            <span class="text-blue-400">{{ item.customerName }}</span>
          </button>
        </td>
        <td :class="className">
          <span
            class="inline-flex capitalize px-2 text-xs shadow-sm hover:shadow transition-shadow duration-200 font-semibold leading-5 rounded-full"
            :class="classStatus(item.status)"
          >
            {{ t(item.status) }}
          </span>
        </td>
        <td :class="className">
          {{ getLocaleDateOrString(item.applicationDate) }}
        </td>
        <td :class="className">
          {{ getLocaleDateOrString(item.startDate) }}
        </td>
        <td :class="className">
          {{ getLocaleDateOrString(item.expireDate) }}
        </td>

        <td v-show="hasUnapproved(items)" :class="className">
          <div class="flex">
            <div v-if="hasUnapproved(item)" class="mr-8">
              <button
                class="rounded capitalize py-1 px-2 text-sm focus:outline-none focus:ring-2 bg-green-100 shadow text-green-800"
                @click="onShowDialogApprove(item.customerId, item.customerName)"
              >
                {{ t('approve') }}
              </button>
            </div>

            <div v-if="hasUnapproved(item)">
              <button
                class="rounded capitalize py-1 px-2 text-sm focus:outline-none focus:ring-2 bg-red-100 shadow text-red-800"
                @click="onShowDialogRefuse(item.customerId, item.customerName)"
              >
                {{ t('refuse') }}
              </button>
            </div>
          </div>
        </td>
      </template>
    </base-table>
  </div>

  <base-dialog :model-value="dialog">
    <component :is="component" v-bind="attrs" ref="dynamicComponent" />
  </base-dialog>
</template>

<script lang="ts">
import { get } from '@/functions/side-effects/api/admin/trials'
import type { Trial } from '@/functions/side-effects/api/admin/trials'
import {
  ref,
  nextTick,
  computed,
  defineComponent,
  defineAsyncComponent,
} from 'vue'
import BaseTable, { defaultStyles } from '@/components/base/BaseTable/Index.vue'
import type { Header } from '@/components/base/BaseTable/logic'
import CustomerDetail from '@/components/trial/CustomerDetail.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import CustomerApprove from '@/components/trial/CustomerApprove.vue'
import CustomerRefuse from '@/components/trial/CustomerRefuse.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'
import { classStatus } from '@/functions/pure/utils'
import { useToggle, useMiltipleDialog } from '@/reactives'
import { catchError } from '@/composites'
import {
  componentResolver,
  attrsResolver,
  hasUnapproved,
  Verdict,
  Item,
  getResponseParser,
  getLocaleDateOrString,
  customStatusFilter,
  sorter,
} from '@/components/trial/logic'
import ja from '@/locales/ja.json'
import en from '@/locales/en.json'
import InputSearch from '@/components/base/inputs/InputSearch.vue'
import Drumroll from '@/components/base/Drumroll.vue'
export default defineComponent({
  components: {
    Drumroll,
    BaseTable,
    CustomerDetail,
    BaseDialog,
    CustomerApprove,
    CustomerRefuse,
    InputSearch,
    MdiMagnify: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiMagnify.vue')
    ),
  },

  setup() {
    const { t } = useI18n({
      messages: {
        ja,
        en,
      },
    })
    const { dispatch } = useStore()
    const { state: isLoading, setState: changeLoading } = useToggle()
    const { dialog, show, hide, candidate } = useMiltipleDialog<Verdict>()
    const { catcher } = catchError<Item[]>([])
    const search = ref<string>('')

    const items = ref<Item[]>([])
    const headers: Header[] = [
      {
        value: 'serviceName',
        text: t('service_name'),
      },
      { value: 'customerName', text: t('organization_name') },
      {
        value: 'status',
        text: t('status'),
        filter: customStatusFilter,
      },
      { value: 'applicationDate', text: t('apply_date') },
      { value: 'startDate', text: t('start_date') },
      { value: 'expireDate', text: t('expire_date') },
      { value: 'actions', sortable: false },
    ]

    const baseTableAttrs = computed(() => ({
      ...defaultStyles,
      headers,
      items: items.value,
      loading: isLoading.value,
      search: search.value,
      pagenation: [15, 30, 'ALL'],
      trKey: ['customerId'],
    }))

    const dynamicComponent = ref<
      InstanceType<
        typeof CustomerDetail | typeof CustomerApprove | typeof CustomerRefuse
      >
    >()
    const component = computed(() => componentResolver(candidate.value))

    const onClick = async ({ customerId }: Trial): Promise<void> => {
      show('info')
      await nextTick()
      const component = dynamicComponent.value as InstanceType<
        typeof CustomerDetail
      >
      component.setId(customerId)
    }

    const onShowDialogApprove = async (
      customerId: string,
      customerName: string
    ): Promise<void> => {
      show('approve')
      await nextTick()

      const component = dynamicComponent.value as InstanceType<
        typeof CustomerApprove
      >
      component.setId(customerId, customerName)
    }

    const onShowDialogRefuse = async (
      customerId: string,
      customerName: string
    ): Promise<void> => {
      show('refuse')
      await nextTick()

      const component = dynamicComponent.value as InstanceType<
        typeof CustomerRefuse
      >
      component.setId(customerId, customerName)
    }

    const success = async (): Promise<void> => {
      changeLoading(true)
      items.value = [
        ...(await get().then(getResponseParser).then(sorter).catch(catcher)),
      ]
      changeLoading(false)
      dispatch(ActionTypes.SetNotice, {
        message: t('updated_status'),
        status: 'success',
      })
    }

    const attrs = computed<{
      [k: string]: Function
    }>(() =>
      attrsResolver(candidate.value, {
        info: {
          onClose: hide,
        },
        approve: {
          onClose: hide,
          onSuccess: () => {
            ;[success, hide].forEach((fn) => fn())
          },
        },
        refuse: {
          onClose: hide,
          onSuccess: () => {
            ;[success, hide].forEach((fn) => fn())
          },
        },
      })
    )

    changeLoading(true)
    get()
      .then(getResponseParser)
      .then(sorter)
      .then((result) => {
        items.value = [...result]
      })
      .catch(catcher)
      .then(() => changeLoading(false))

    return {
      t,
      items,
      dialog,
      component,
      attrs,
      hide,
      search,
      dynamicComponent,
      onClick,
      classStatus,
      hasUnapproved,
      onShowDialogApprove,
      onShowDialogRefuse,
      getLocaleDateOrString,
      baseTableAttrs,
    }
  },
})
</script>
<style lang="scss" src="@/assets/styles/transition.scss"></style>

<i18n lang="yml">
en:
  service_name: service
  organization_name: company
  count: '{0}'
  refuse: Refuse
ja:
  service_name: サービス
  organization_name: 組織名/屋号
  count: '{0} 件'
  refuse: 拒否
</i18n>
