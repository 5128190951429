<template>
  <div class="overflow-hidden" style="line-height: 0">
    <div
      class="w-1/2 inline-block relative h-1 fluentProgressBar-waiting"
    ></div>
  </div>
</template>

<style scoped>
.fluentProgressBar-waiting {
  background: linear-gradient(
    to right,
    rgba(0, 120, 212, 0) 0%,
    rgba(0, 120, 212, 1) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  animation: progressAnim 1.5s linear infinite;
}

@keyframes progressAnim {
  0% {
    left: -50%;
  }

  100% {
    left: 100%;
  }
}
</style>
