import { computed, ref, ComputedRef, Ref, watch } from 'vue'
import { sortBy, compose, toLower, toString, prop, sort } from 'rambda'

interface Item {
  class?: string
  [k: string]: string | number | Date | undefined
}

const DEFAULT_ROWS = 15
const diff = (a: number | 'ALL', b: number | 'ALL'): number => {
  if (a === 'ALL' || b === 'ALL') return 1
  return a - b
}
const getInitRow = (row: number | boolean | (number | 'ALL')[]): number => {
  switch (typeof row) {
    case 'number': {
      return row
    }

    case 'boolean': {
      return DEFAULT_ROWS
    }

    case 'object': {
      const sorted = sort(diff, row)[0]
      return sorted === 'ALL' ? DEFAULT_ROWS : sorted || DEFAULT_ROWS
    }
  }
}

const usePagenagion = (
  items: ComputedRef<readonly Item[]>,
  pagenation: Ref<number | boolean | (number | 'ALL')[]>,
  search: Ref<string>
) => {
  const page = ref<number>(1)
  const _tmpPage = ref<number>(page.value)
  const rows = ref<number | 'ALL'>(getInitRow(pagenation.value))
  const _rows = computed<number>(() =>
    rows.value === 'ALL' ? items.value.length : rows.value
  )

  const pages = computed<number>(() => {
    if (
      !pagenation.value ||
      typeof pagenation.value === 'boolean' ||
      !items.value.length
    )
      return 1
    return Math.ceil(items.value.length / _rows.value)
  })

  const canNext = computed<boolean>(() => page.value < pages.value)
  const canPrev = computed<boolean>(() => page.value > 1)
  const next = (): void => {
    if (canNext.value) {
      page.value++
    }
  }

  const prev = (): void => {
    if (canPrev.value) {
      page.value--
    }
  }

  watch(search, (now, prev) => {
    if (!prev.length) {
      _tmpPage.value = page.value
      page.value = 1
    }
    if (now.length > 1) {
      page.value = 1
    }
    if (!now.length) {
      page.value = _tmpPage.value
      _tmpPage.value = 1
    }
  })

  watch(rows, () => {
    _tmpPage.value = 1
    page.value = 1
  })

  const pagedItems = computed<readonly Item[]>(() => {
    if (!pagenation.value || typeof pagenation.value === 'boolean')
      return items.value
    const tempPage = pages.value < page.value ? pages.value : page.value

    return items.value.slice(
      (tempPage - 1) * _rows.value,
      _rows.value + (tempPage - 1) * _rows.value
    )
  })

  return {
    rows,
    page,
    pages,
    next,
    prev,
    pagedItems,
    canNext,
    canPrev,
  }
}

type Header = {
  text?: string
  class?: string
  value: string | number
  filterable?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: (val: any, lowerCaseSearch: string) => boolean
  sortable?: boolean
}

type Order = 'ASC' | 'DESC'

const objectOrPrimitive = (val: string | number | Date) => {
  if (val instanceof Date) {
    return val
  } else if (typeof val === 'string') {
    return toLower(val)
  } else if (typeof val === 'number') {
    return toLower(toString(val))
  } else {
    return val
  }
}

const sortByNameCaseInsensitive = (key: string | number) =>
  sortBy(compose(objectOrPrimitive, prop(key)))

const getOrderBy = (payload: Order): Order => {
  switch (payload) {
    case 'ASC': {
      return 'DESC'
    }
    case 'DESC': {
      return 'ASC'
    }
  }
}

const isUndefinedOrTrue = (val: boolean | undefined): boolean =>
  typeof val === 'undefined' || val

const getKey = (item: Item, key: string | string[]): string => {
  if (typeof key === 'string') return item[key]?.toString() || ''
  return key.map((k) => item[k]).join('-')
}

export {
  Header,
  Item,
  Order,
  usePagenagion,
  sortByNameCaseInsensitive,
  isUndefinedOrTrue,
  getKey,
  diff,
  getOrderBy,
  DEFAULT_ROWS,
}
