
import {
  computed,
  defineComponent,
  ref,
  defineAsyncComponent,
  toRefs,
} from 'vue'
import { useI18n } from 'vue-i18n'
import ProgressBar from '@/components/base/loaders/ProgressBar.vue'
import MdiSortAlphabeticalDescending from '@/components/base/icons/mdi/MdiSortAlphabeticalDescending.vue'
import { reverse } from 'rambda'
import {
  Order,
  usePagenagion,
  sortByNameCaseInsensitive,
  isUndefinedOrTrue,
  getKey,
  diff,
  getOrderBy,
} from '@/components/base/BaseTable/logic'
import type { Item, Header } from '@/components/base/BaseTable/logic'
import { sort } from 'rambda'

const defaultStyles = {
  classTable: 'min-w-full divide-y divide-gray-200',
  classThead: 'bg-gray-50 whitespace-nowrap uppercase',
  classTh:
    'p-2 sm:p-3 lg:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase',
  classTbody: 'bg-white divide-y divide-gray-200',
  classTr: 'transition text-gray-600 hover:bg-gray-100 hover:shadow-lg',
  classTd: 'px-2 sm:px-4 lg:px-6 py-1 sm:py-2 lg:py-4 whitespace-nowrap',
  classTdNoData: 'p-1 md:p-2 lg:p-4 text-center text-gray-500',
}

export default defineComponent({
  components: {
    ProgressBar,
    MdiSortAlphabeticalAscending: defineAsyncComponent(
      () =>
        import('@/components/base/icons/mdi/MdiSortAlphabeticalAscending.vue')
    ),
    MdiSortAlphabeticalDescending,
    Pagenation: defineAsyncComponent(
      () => import('@/components/base/BaseTable/Pagenation.vue')
    ),
  },

  props: {
    headers: {
      type: Array as () => Header[],
      default: () => [],
    },

    items: {
      type: Array as () => Item[],
      default: () => [],
    },
    classTable: {
      type: String,
      default: '',
    },
    classThead: {
      type: String,
      default: '',
    },
    classTbody: {
      type: String,
      default: '',
    },
    classTh: {
      type: String,
      default: '',
    },
    classTr: {
      type: String,
      default: '',
    },
    classTd: {
      type: String,
      default: '',
    },
    classTdNoData: {
      type: String,
      default: '',
    },
    trKey: {
      type: [String, Array as () => string[]],
      default: '',
    },
    noData: {
      type: String,
      default: '',
    },
    loading: Boolean,
    loadingText: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    pagenation: {
      type: [Number, Boolean, Array as () => (number | 'ALL')[]],
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const headersRef = computed<Header[]>(() => props.headers || [])
    const itemsRef = computed<Item[]>(() => props.items || [])
    const sortByKey = ref<number | string>('')
    const orderBy = ref<Order>('ASC')

    const onSort = (payload: string | number): void => {
      if (sortByKey.value === payload) {
        orderBy.value = getOrderBy(orderBy.value)
        if (orderBy.value === 'ASC') {
          sortByKey.value = ''
        }
      } else {
        sortByKey.value = payload
        orderBy.value = 'ASC'
      }
    }

    const isVisibleHeader = computed<boolean>(() =>
      headersRef.value.some((header) => !!header.text)
    )
    const lowerCaseSearch = computed<string>(() => props.search.toLowerCase())

    const filterableHeaderValues = computed<Pick<Header, 'value' | 'filter'>[]>(
      () =>
        headersRef.value
          .filter(({ filterable }) => isUndefinedOrTrue(filterable))
          .map(({ value, filter }) => ({ value, filter }))
    )
    const filteredItems = computed<Item[]>(() => {
      if (!lowerCaseSearch.value || !filterableHeaderValues.value.length)
        return itemsRef.value
      return itemsRef.value.filter((item) =>
        filterableHeaderValues.value.some(({ value: v, filter }) => {
          const value = item[v]
          if (typeof filter === 'function') {
            return filter(value, lowerCaseSearch.value)
          }
          if (typeof value === 'string') {
            return value.toLowerCase().includes(lowerCaseSearch.value)
          } else if (typeof value === 'number') {
            return value.toString().includes(lowerCaseSearch.value)
          } else if (value instanceof Date) {
            return value.toLocaleString().includes(lowerCaseSearch.value)
          }
        })
      )
    })

    const sortedItems = computed<readonly Item[]>(() => {
      if (!sortByKey.value) return filteredItems.value
      const sorted = sortByNameCaseInsensitive(sortByKey.value)(
        filteredItems.value
      )
      return orderBy.value === 'ASC' ? sorted : reverse(sorted)
    })

    const { pagenation, search } = toRefs(props)

    const {
      rows,
      page,
      pages,
      pagedItems,
      next,
      prev,
      canNext,
      canPrev,
    } = usePagenagion(sortedItems, pagenation, search)

    const noDataText = computed<string>(() => props.noData || t('no_data'))
    const loadingPlaceholder = computed<string>(
      () => props.loadingText || t('loading')
    )
    const loadingOrNoDataText = computed<string>(() =>
      props.loading ? loadingPlaceholder.value : noDataText.value
    )

    return {
      t,
      headersRef,
      itemsRef,
      filteredItems,
      isVisibleHeader,
      loadingPlaceholder,
      loadingOrNoDataText,
      onSort,
      sortByKey,
      orderBy,
      sortedItems,
      pagedItems,
      isUndefinedOrTrue,
      getKey,
      page,
      pages,
      next,
      prev,
      sort,
      canNext,
      canPrev,
      rows,
      diff,
    }
  },
})

// eslint-disable-next-line no-undef
export { Header, Item, defaultStyles }
