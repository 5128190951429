<template>
  <button :title="t('title')">
    <mdi-close />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import MdiClose from '@/components/base/icons/mdi/MdiClose.vue'
export default defineComponent({
  components: {
    MdiClose,
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<i18n lang="yml">
en:
  title: Close
ja:
  title: 閉じる
</i18n>
