
import BaseTable from '@/components/base/BaseTable/Index.vue'
import MdiLoading from '@/components/base/icons/mdi/MdiLoading.vue'
import { defineComponent, computed, ref, defineAsyncComponent } from 'vue'
import { get } from '@/functions/side-effects/api/admin/customer'
import { useI18n } from 'vue-i18n'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
import { useToggle } from '@/reactives'
import { catchError } from '@/composites'
export default defineComponent({
  components: {
    BaseTable,
    MdiLoading,
    MdiAlert: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiAlert.vue')
    ),
    ButtonClose,
  },
  emits: ['close'],

  setup() {
    const { t } = useI18n()
    const { state: hasError, setState } = useToggle()
    const { catcher } = catchError(undefined)

    const headers = [
      {
        value: 'key',
      },
      {
        value: 'value',
      },
    ]

    const name = ref('')
    const customerInfo = ref<{ [k: string]: string }>()

    const setId = (customerId: string) => {
      get(customerId)
        .then(({ name: n, ...rest }) => {
          name.value = n
          customerInfo.value = rest
        })
        .catch(catcher)
        .then(() => setState(true))
    }

    const items = computed(() =>
      customerInfo.value
        ? Object.entries(customerInfo.value)
            .map(([key, value]) => ({
              key,
              value,
            }))
            .filter(({ key }) => key !== 'name')
        : []
    )

    return {
      hasError,
      headers,
      items,
      name,
      setId,
      t,
    }
  },
})
