import { http } from '@/plugins/ky'
import type { Service } from '@/types'
import { join } from 'path'
import { ADMIN_CUSTOMER } from '@/functions/side-effects/api/admin/customer'
const ADMIN_CUSTOMER_APPICATION = join(ADMIN_CUSTOMER, 'application')

type RequestBody = {
  customerId: string
  service: Service
  isApproval: boolean
}

const post = ({ customerId, service, isApproval }: RequestBody) =>
  http.post(ADMIN_CUSTOMER_APPICATION, {
    json: {
      customerId,
      service,
      approval: isApproval,
    },
  })
export { post, RequestBody, ADMIN_CUSTOMER_APPICATION }
