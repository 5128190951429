
import TrialTable from '@/components/trial/TrialTable.vue'
import { defineComponent } from 'vue'

import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: {
    TrialTable,
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
