
import { defineComponent, ref, onBeforeMount, onUnmounted, computed } from 'vue'
import MdiMagnify from '@/components/base/icons/mdi/MdiMagnify.vue'
import MdiClose from '@/components/base/icons/mdi/MdiClose.vue'
import MdiSlashForward from '@/components/base/icons/mdi/MdiSlashForward.vue'
import { useI18n } from 'vue-i18n'
import debounce from 'lodash.debounce'

export default defineComponent({
  components: {
    MdiMagnify,
    MdiClose,
    MdiSlashForward,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],

  setup(_, { emit }) {
    const { t } = useI18n()
    const input = ref<HTMLInputElement>()
    const onFocus = (): void => input.value?.focus()

    const focusOnSlash = async ({ key }: KeyboardEvent): Promise<void> => {
      if (key !== '/' || !input.value || document.activeElement === input.value)
        return
      onFocus()
    }

    const onEscape = (): void => {
      input.value?.blur()
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', focusOnSlash)
    })

    onUnmounted(() => {
      window.removeEventListener('keyup', focusOnSlash)
    })

    const emitter = ({ target }: Event) => {
      if (!(target instanceof HTMLInputElement)) return
      emit('update:modelValue', target.value)
    }

    const onInput = debounce(emitter, 200)

    return {
      t,
      input,
      onEscape,
      onFocus,
      onInput,
    }
  },
})
