
import { defineComponent } from 'vue'
import MdiLoading from '@/components/base/icons/mdi/MdiLoading.vue'

export default defineComponent({
  components: {
    MdiLoading,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    classLoader: {
      type: String,
      default: 'animate-spin',
    },
  },
})
