<template>
  <div class="p-4">
    <button-close
      class="rounded-full shadow bg-gray-50 hover:bg-gray-100 p-1 hover:shadow-md transition duration-200 focus:outline-none focus:ring-2"
      @click="$emit('close')"
    />

    <span class="ml-4 align-middle capitalize">{{ t('title') }}</span>
  </div>
  <div class="px-4">
    <h2 class="px-2 mb-2">{{ name }}</h2>

    <div
      v-if="items.length"
      class="overflow-scroll my-4 rounded hover:shadow-md transition-shadow duration-200 shadow"
    >
      <base-table
        class-table="min-w-full "
        class-tr="hover:bg-gray-100"
        :headers="headers"
        :items="items"
      >
        <template #item="{ item }">
          <td class="py-2 px-3 font-bold capitalize">{{ item.key }}</td>
          <td class="py-2 px-3 border-l">{{ item.value }}</td>
        </template>
      </base-table>
    </div>
    <div v-else class="text-center">
      <mdi-alert v-if="hasError" class="text-red-500 w-14 h-14" />
      <mdi-loading v-else class="animate-spin text-blue-500 w-14 h-14" />
    </div>
  </div>
</template>

<script lang="ts">
import BaseTable from '@/components/base/BaseTable/Index.vue'
import MdiLoading from '@/components/base/icons/mdi/MdiLoading.vue'
import { defineComponent, computed, ref, defineAsyncComponent } from 'vue'
import { get } from '@/functions/side-effects/api/admin/customer'
import { useI18n } from 'vue-i18n'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
import { useToggle } from '@/reactives'
import { catchError } from '@/composites'
export default defineComponent({
  components: {
    BaseTable,
    MdiLoading,
    MdiAlert: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiAlert.vue')
    ),
    ButtonClose,
  },
  emits: ['close'],

  setup() {
    const { t } = useI18n()
    const { state: hasError, setState } = useToggle()
    const { catcher } = catchError(undefined)

    const headers = [
      {
        value: 'key',
      },
      {
        value: 'value',
      },
    ]

    const name = ref('')
    const customerInfo = ref<{ [k: string]: string }>()

    const setId = (customerId: string) => {
      get(customerId)
        .then(({ name: n, ...rest }) => {
          name.value = n
          customerInfo.value = rest
        })
        .catch(catcher)
        .then(() => setState(true))
    }

    const items = computed(() =>
      customerInfo.value
        ? Object.entries(customerInfo.value)
            .map(([key, value]) => ({
              key,
              value,
            }))
            .filter(({ key }) => key !== 'name')
        : []
    )

    return {
      hasError,
      headers,
      items,
      name,
      setId,
      t,
    }
  },
})
</script>

<i18n lang="yml">
en:
  title: customer detail
ja:
  title: カスタマー詳細
</i18n>
