<template>
  <span>{{ rollingCount }}</span>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },

    duration: {
      type: Number,
      default: 100,
    },
  },

  setup(props) {
    const intervalId = ref()
    const rollingCount = ref(props.count)

    const terminateDrumRolling = (endVal: number): void => {
      rollingCount.value = endVal
      clearInterval(intervalId.value)
    }

    const countUp = (newVal: number, countInterval: number): void => {
      if (newVal <= rollingCount.value) {
        terminateDrumRolling(newVal)
        return
      }
      rollingCount.value = rollingCount.value + countInterval
    }

    const countDown = (newVal: number, countInterval: number): void => {
      if (newVal >= rollingCount.value) {
        terminateDrumRolling(newVal)
        return
      }
      rollingCount.value = rollingCount.value - countInterval
    }

    const performDrumRolling = (newVal: number, oldVal: number): void => {
      terminateDrumRolling(oldVal)
      const countInterval =
        Math.abs(Math.floor((newVal - oldVal) / props.duration)) || 1
      if (oldVal < newVal) {
        intervalId.value = setInterval((): void => {
          countUp(newVal, countInterval)
        }, 20)
      } else {
        intervalId.value = setInterval((): void => {
          countDown(newVal, countInterval)
        }, 20)
      }
    }
    watch(
      () => props.count,
      (now, prev) => {
        performDrumRolling(now, prev)
      }
    )

    return {
      rollingCount,
    }
  },
})
</script>
