<template>
  <table :class="classTable">
    <thead v-show="isVisibleHeader" :class="classThead">
      <tr>
        <slot name="header" :headers="headersRef">
          <template v-for="header in headersRef" :key="header.value">
            <slot :name="`header:${header.value}`" :items="sortedItems">
              <th :class="[header.class, classTh]" class="group">
                {{ header.text }}
                <slot name="sort">
                  <button
                    v-if="isUndefinedOrTrue(header.sortable)"
                    class="group-hover:opacity-100 text-gray-400 hover:text-gray-600 opacity-0 duration-500 transition"
                    :class="{
                      'opacity-100 text-blue-400 hover:text-blue-400':
                        sortByKey === header.value,
                    }"
                    @click="onSort(header.value)"
                  >
                    <slot
                      v-if="orderBy === 'ASC' || sortByKey !== header.value"
                      name="asc-icon"
                    >
                      <mdi-sort-alphabetical-ascending
                        :title="t('asc')"
                        class="w-4 h-4"
                      />
                    </slot>

                    <slot v-else-if="orderBy === 'DESC'" name="desc-icon">
                      <mdi-sort-alphabetical-descending
                        :title="t('desc')"
                        class="w-4 h-4"
                      />
                    </slot>
                  </button>
                </slot>
              </th>
            </slot>
          </template>
        </slot>
      </tr>
    </thead>

    <thead v-show="loading">
      <tr>
        <th :colspan="headersRef.length">
          <progress-bar class="w-full" />
        </th>
      </tr>
    </thead>

    <transition name="fade" mode="out-in">
      <tbody v-if="pagedItems.length" :class="classTbody">
        <tr
          v-for="(item, index) in pagedItems"
          :key="trKey ? getKey(item, trKey) : index"
          :class="classTr"
        >
          <slot
            name="item"
            :item="item"
            :items="sortedItems"
            :className="classTd"
          >
            <td v-for="{ value } in headersRef" :key="value" :class="classTd">
              <slot name="value" :value="value">
                {{ item[value] }}
              </slot>
            </td>
          </slot>
        </tr>
      </tbody>

      <tbody v-else :class="classTbody">
        <tr :class="classTr">
          <td
            :class="classTdNoData"
            class="capitalize"
            :colspan="headersRef.length"
          >
            {{ loadingOrNoDataText }}
          </td>
        </tr>
      </tbody>
    </transition>
  </table>

  <transition name="slide-down" mode="out-in">
    <slot
      name="pagenation"
      :canPrev="canPrev"
      :canNext="canNext"
      :next="next"
      :prev="prev"
      :page="page"
      :pages="pages"
    >
      <pagenation
        v-if="pagenation && pagedItems.length"
        v-model="page"
        v-model:rows="rows"
        class="sticky left-0 border-t"
        :pages="pages"
        :pagenation="
          Array.isArray(pagenation) ? sort(diff, pagenation) : pagenation
        "
        :items="[...pagedItems]"
        :disabled-next="!canNext"
        :disabled-prev="!canPrev"
        @next="next"
        @prev="prev"
      />
    </slot>
  </transition>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  defineAsyncComponent,
  toRefs,
} from 'vue'
import { useI18n } from 'vue-i18n'
import ProgressBar from '@/components/base/loaders/ProgressBar.vue'
import MdiSortAlphabeticalDescending from '@/components/base/icons/mdi/MdiSortAlphabeticalDescending.vue'
import { reverse } from 'rambda'
import {
  Order,
  usePagenagion,
  sortByNameCaseInsensitive,
  isUndefinedOrTrue,
  getKey,
  diff,
  getOrderBy,
} from '@/components/base/BaseTable/logic'
import type { Item, Header } from '@/components/base/BaseTable/logic'
import { sort } from 'rambda'

const defaultStyles = {
  classTable: 'min-w-full divide-y divide-gray-200',
  classThead: 'bg-gray-50 whitespace-nowrap uppercase',
  classTh:
    'p-2 sm:p-3 lg:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase',
  classTbody: 'bg-white divide-y divide-gray-200',
  classTr: 'transition text-gray-600 hover:bg-gray-100 hover:shadow-lg',
  classTd: 'px-2 sm:px-4 lg:px-6 py-1 sm:py-2 lg:py-4 whitespace-nowrap',
  classTdNoData: 'p-1 md:p-2 lg:p-4 text-center text-gray-500',
}

export default defineComponent({
  components: {
    ProgressBar,
    MdiSortAlphabeticalAscending: defineAsyncComponent(
      () =>
        import('@/components/base/icons/mdi/MdiSortAlphabeticalAscending.vue')
    ),
    MdiSortAlphabeticalDescending,
    Pagenation: defineAsyncComponent(
      () => import('@/components/base/BaseTable/Pagenation.vue')
    ),
  },

  props: {
    headers: {
      type: Array as () => Header[],
      default: () => [],
    },

    items: {
      type: Array as () => Item[],
      default: () => [],
    },
    classTable: {
      type: String,
      default: '',
    },
    classThead: {
      type: String,
      default: '',
    },
    classTbody: {
      type: String,
      default: '',
    },
    classTh: {
      type: String,
      default: '',
    },
    classTr: {
      type: String,
      default: '',
    },
    classTd: {
      type: String,
      default: '',
    },
    classTdNoData: {
      type: String,
      default: '',
    },
    trKey: {
      type: [String, Array as () => string[]],
      default: '',
    },
    noData: {
      type: String,
      default: '',
    },
    loading: Boolean,
    loadingText: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    pagenation: {
      type: [Number, Boolean, Array as () => (number | 'ALL')[]],
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const headersRef = computed<Header[]>(() => props.headers || [])
    const itemsRef = computed<Item[]>(() => props.items || [])
    const sortByKey = ref<number | string>('')
    const orderBy = ref<Order>('ASC')

    const onSort = (payload: string | number): void => {
      if (sortByKey.value === payload) {
        orderBy.value = getOrderBy(orderBy.value)
        if (orderBy.value === 'ASC') {
          sortByKey.value = ''
        }
      } else {
        sortByKey.value = payload
        orderBy.value = 'ASC'
      }
    }

    const isVisibleHeader = computed<boolean>(() =>
      headersRef.value.some((header) => !!header.text)
    )
    const lowerCaseSearch = computed<string>(() => props.search.toLowerCase())

    const filterableHeaderValues = computed<Pick<Header, 'value' | 'filter'>[]>(
      () =>
        headersRef.value
          .filter(({ filterable }) => isUndefinedOrTrue(filterable))
          .map(({ value, filter }) => ({ value, filter }))
    )
    const filteredItems = computed<Item[]>(() => {
      if (!lowerCaseSearch.value || !filterableHeaderValues.value.length)
        return itemsRef.value
      return itemsRef.value.filter((item) =>
        filterableHeaderValues.value.some(({ value: v, filter }) => {
          const value = item[v]
          if (typeof filter === 'function') {
            return filter(value, lowerCaseSearch.value)
          }
          if (typeof value === 'string') {
            return value.toLowerCase().includes(lowerCaseSearch.value)
          } else if (typeof value === 'number') {
            return value.toString().includes(lowerCaseSearch.value)
          } else if (value instanceof Date) {
            return value.toLocaleString().includes(lowerCaseSearch.value)
          }
        })
      )
    })

    const sortedItems = computed<readonly Item[]>(() => {
      if (!sortByKey.value) return filteredItems.value
      const sorted = sortByNameCaseInsensitive(sortByKey.value)(
        filteredItems.value
      )
      return orderBy.value === 'ASC' ? sorted : reverse(sorted)
    })

    const { pagenation, search } = toRefs(props)

    const {
      rows,
      page,
      pages,
      pagedItems,
      next,
      prev,
      canNext,
      canPrev,
    } = usePagenagion(sortedItems, pagenation, search)

    const noDataText = computed<string>(() => props.noData || t('no_data'))
    const loadingPlaceholder = computed<string>(
      () => props.loadingText || t('loading')
    )
    const loadingOrNoDataText = computed<string>(() =>
      props.loading ? loadingPlaceholder.value : noDataText.value
    )

    return {
      t,
      headersRef,
      itemsRef,
      filteredItems,
      isVisibleHeader,
      loadingPlaceholder,
      loadingOrNoDataText,
      onSort,
      sortByKey,
      orderBy,
      sortedItems,
      pagedItems,
      isUndefinedOrTrue,
      getKey,
      page,
      pages,
      next,
      prev,
      sort,
      canNext,
      canPrev,
      rows,
      diff,
    }
  },
})

// eslint-disable-next-line no-undef
export { Header, Item, defaultStyles }
</script>

<i18n lang="yml">
en:
  no_data: no data available
  loading: loading
  asc: asc
  desc: desc

ja:
  no_data: データがありません
  loading: ロード中
  asc: 昇順
  desc: 降順
</i18n>

<style lang="scss" src="@/assets/styles/transition.scss"></style>
