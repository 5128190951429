
import { get } from '@/functions/side-effects/api/admin/trials'
import type { Trial } from '@/functions/side-effects/api/admin/trials'
import {
  ref,
  nextTick,
  computed,
  defineComponent,
  defineAsyncComponent,
} from 'vue'
import BaseTable, { defaultStyles } from '@/components/base/BaseTable/Index.vue'
import type { Header } from '@/components/base/BaseTable/logic'
import CustomerDetail from '@/components/trial/CustomerDetail.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import CustomerApprove from '@/components/trial/CustomerApprove.vue'
import CustomerRefuse from '@/components/trial/CustomerRefuse.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'
import { classStatus } from '@/functions/pure/utils'
import { useToggle, useMiltipleDialog } from '@/reactives'
import { catchError } from '@/composites'
import {
  componentResolver,
  attrsResolver,
  hasUnapproved,
  Verdict,
  Item,
  getResponseParser,
  getLocaleDateOrString,
  customStatusFilter,
  sorter,
} from '@/components/trial/logic'
import ja from '@/locales/ja.json'
import en from '@/locales/en.json'
import InputSearch from '@/components/base/inputs/InputSearch.vue'
import Drumroll from '@/components/base/Drumroll.vue'
export default defineComponent({
  components: {
    Drumroll,
    BaseTable,
    CustomerDetail,
    BaseDialog,
    CustomerApprove,
    CustomerRefuse,
    InputSearch,
    MdiMagnify: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiMagnify.vue')
    ),
  },

  setup() {
    const { t } = useI18n({
      messages: {
        ja,
        en,
      },
    })
    const { dispatch } = useStore()
    const { state: isLoading, setState: changeLoading } = useToggle()
    const { dialog, show, hide, candidate } = useMiltipleDialog<Verdict>()
    const { catcher } = catchError<Item[]>([])
    const search = ref<string>('')

    const items = ref<Item[]>([])
    const headers: Header[] = [
      {
        value: 'serviceName',
        text: t('service_name'),
      },
      { value: 'customerName', text: t('organization_name') },
      {
        value: 'status',
        text: t('status'),
        filter: customStatusFilter,
      },
      { value: 'applicationDate', text: t('apply_date') },
      { value: 'startDate', text: t('start_date') },
      { value: 'expireDate', text: t('expire_date') },
      { value: 'actions', sortable: false },
    ]

    const baseTableAttrs = computed(() => ({
      ...defaultStyles,
      headers,
      items: items.value,
      loading: isLoading.value,
      search: search.value,
      pagenation: [15, 30, 'ALL'],
      trKey: ['customerId'],
    }))

    const dynamicComponent = ref<
      InstanceType<
        typeof CustomerDetail | typeof CustomerApprove | typeof CustomerRefuse
      >
    >()
    const component = computed(() => componentResolver(candidate.value))

    const onClick = async ({ customerId }: Trial): Promise<void> => {
      show('info')
      await nextTick()
      const component = dynamicComponent.value as InstanceType<
        typeof CustomerDetail
      >
      component.setId(customerId)
    }

    const onShowDialogApprove = async (
      customerId: string,
      customerName: string
    ): Promise<void> => {
      show('approve')
      await nextTick()

      const component = dynamicComponent.value as InstanceType<
        typeof CustomerApprove
      >
      component.setId(customerId, customerName)
    }

    const onShowDialogRefuse = async (
      customerId: string,
      customerName: string
    ): Promise<void> => {
      show('refuse')
      await nextTick()

      const component = dynamicComponent.value as InstanceType<
        typeof CustomerRefuse
      >
      component.setId(customerId, customerName)
    }

    const success = async (): Promise<void> => {
      changeLoading(true)
      items.value = [
        ...(await get().then(getResponseParser).then(sorter).catch(catcher)),
      ]
      changeLoading(false)
      dispatch(ActionTypes.SetNotice, {
        message: t('updated_status'),
        status: 'success',
      })
    }

    const attrs = computed<{
      [k: string]: Function
    }>(() =>
      attrsResolver(candidate.value, {
        info: {
          onClose: hide,
        },
        approve: {
          onClose: hide,
          onSuccess: () => {
            ;[success, hide].forEach((fn) => fn())
          },
        },
        refuse: {
          onClose: hide,
          onSuccess: () => {
            ;[success, hide].forEach((fn) => fn())
          },
        },
      })
    )

    changeLoading(true)
    get()
      .then(getResponseParser)
      .then(sorter)
      .then((result) => {
        items.value = [...result]
      })
      .catch(catcher)
      .then(() => changeLoading(false))

    return {
      t,
      items,
      dialog,
      component,
      attrs,
      hide,
      search,
      dynamicComponent,
      onClick,
      classStatus,
      hasUnapproved,
      onShowDialogApprove,
      onShowDialogRefuse,
      getLocaleDateOrString,
      baseTableAttrs,
    }
  },
})
