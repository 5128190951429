
import { computed, ref, watch, defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    classDialog: {
      type: String,
      default: '',
    },
    classInner: {
      type: String,
      default: '',
    },
    transition: {
      type: String,
      default: 'fade',
    },
    to: {
      type: String,
      default: '#app',
    },
  },

  setup(props) {
    const dialog = ref<boolean>(props.modelValue || false)
    watch(
      () => props.modelValue,
      (now) => {
        if (typeof now === 'boolean') dialog.value = now
      }
    )
    const changeState = (payload: boolean) => {
      dialog.value = payload
    }
    const openDialog = (): void => changeState(true)
    const hideDialog = (): void => changeState(false)

    const dialogClass = computed<string>(
      () =>
        props.classDialog || 'px-3 backdrop-blur-2 bg-gray-800 bg-opacity-25'
    )
    const innerClass = computed<string>(
      () => props.classDialog || 'min-w-72 w-1/3'
    )

    return {
      dialog,
      dialogClass,
      innerClass,
      openDialog,
      hideDialog,
    }
  },
})
