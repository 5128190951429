import { render } from "./InputSearch.vue?vue&type=template&id=0d4b7eb0"
import script from "./InputSearch.vue?vue&type=script&lang=ts"
export * from "./InputSearch.vue?vue&type=script&lang=ts"

import "./InputSearch.vue?vue&type=style&index=0&id=0d4b7eb0&lang=css"
script.render = render
/* custom blocks */
import block0 from "./InputSearch.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(script)


export default script