<template>
  <h1 class="text-2xl mb-2 lg:mb-10 p-2">{{ t('title') }}</h1>

  <trial-table />
</template>

<script lang="ts">
import TrialTable from '@/components/trial/TrialTable.vue'
import { defineComponent } from 'vue'

import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: {
    TrialTable,
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<i18n>
{
  "en": {
    "title": "List of customers with services during trial"
  },
  "ja": {
    "title": "トライアル中のサービスがあるカスタマー一覧"
  }
}
</i18n>
